export const Game = async (gameName) => {
  if (gameName === 'TestSlot5') {
    const gameModule = await import('./FishyGuy');
    return gameModule.FishyGuy;
  }

  if (gameName === 'TestSlot4') {
    const gameModule = await import('./TolucaFortune');
    return gameModule.TolucaFortune;
  }

  try {
    const gameModule = await import(`./${gameName}.js`);
    return gameModule[gameName];
  } catch (e) {
    const gameModule = await import('./Slot');
    return gameModule.Slot;
  }
};

export { Wheel } from '@/games/Wheel';
