import { assign, defaultTo, each, has, isNil, isFunction, includes, findIndex, range, camelCase } from 'lodash';
import { Howler as SoundLibrary } from 'howler';
import { v4 as uuid } from 'uuid';
import FontFaceObserver from 'fontfaceobserver';
import WebFontLoader from 'webfontloader';
import { Assets, BitmapFont, utils, VideoResource } from '../pixi';
import { LogType } from '../models';
import logger from './Logger';
import { getCustomAssetsForGame } from './CustomAssets';

const eventPrefix = 'Nsft.';
const loaderContext = 'CasinoLoaderProgress';

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function loadFonts(fonts = [], locale) { // eslint-disable-line default-param-last
  try {
    const defaultFontFamily = 'Roboto';
    const fontNames = fonts.map((font) => font.name);
    const specialChars = 'ČŠĆĐŽčšćđžÂĂÎȚȘțșăâîáéíóúàòâêôãõç ';
    const bitmapChars = [
      ...BitmapFont.ALPHANUMERIC,
      '–,.+-:()!/|"%',
    ];
    const fontSize = 54;
    const lineHeight = Math.floor(fontSize * 1.08);

    await WebFontLoader.load({
      google: {
        families: [
          `${defaultFontFamily}:300,500,700,900`,
          ...fontNames,
        ],
      },
    });

    if (locale !== 'en') {
      // Fetch font for specialChars
      await WebFontLoader.load({
        google: {
          families: [
            `${defaultFontFamily}:300,500,700,900`,
            ...fontNames,
          ],
          text: specialChars,
        },
      });

      bitmapChars.push(specialChars);
    }

    let loaderFonts = [
      new FontFaceObserver(defaultFontFamily, { weight: 300 }),
      new FontFaceObserver(defaultFontFamily, { weight: 500 }),
      new FontFaceObserver(defaultFontFamily, { weight: 700 }),
      new FontFaceObserver(defaultFontFamily, { weight: 900 }),
    ];

    loaderFonts = loaderFonts.concat(fonts.map((font) => new FontFaceObserver(font.name)));

    /* Fonts load timeout increased by 10 seconds in case of low connection speed,
       default timeout for giving up on font loading is 3 seconds */

    await Promise.all(loaderFonts.map((font) => font.load(null, 10000)));

    BitmapFont.from(`${defaultFontFamily}Light`, {
      fontFamily: defaultFontFamily,
      fontSize,
      fontWeight: 300,
      fill: 'white',
      lineHeight,
    }, {
      chars: bitmapChars,
    });

    BitmapFont.from(`${defaultFontFamily}Normal`, {
      fontFamily: defaultFontFamily,
      fontSize,
      fontWeight: 500,
      fill: 'white',
      lineHeight,
    }, {
      chars: bitmapChars,
    });

    BitmapFont.from(`${defaultFontFamily}SemiBold`, {
      fontFamily: defaultFontFamily,
      fontSize,
      fontWeight: 700,
      fill: 'white',
      lineHeight,
    }, {
      chars: bitmapChars,
    });

    BitmapFont.from(`${defaultFontFamily}Bold`, {
      fontFamily: defaultFontFamily,
      fontSize,
      fontWeight: 900,
      fill: 'white',
      lineHeight,
    }, {
      chars: bitmapChars,
    });
  } catch (error) {
    logger.log(LogType.Warn, 'FontLoadError', error.message);
  }
}

export async function loadAssets(state, customAssetsBaseUrl, customAssets, fonts, manualEnd = false, locale = 'en') {
  let gameCustomAssets = customAssets;
  const gameAssetsBaseUrl = defaultTo(customAssetsBaseUrl, 'https://storage.googleapis.com/nsoftcasino');
  const gameCustomAssetsBaseUrl = `${gameAssetsBaseUrl}/resources/slots`;
  const gameAssets = state ? defaultTo(state.game.assets, {}) : {};
  const gameAssetKeys = [];
  const loader = Assets;
  const resources = {};

  /*
  Load fonts.
  */
  await loadFonts(fonts, locale);

  /*
  Add common slot assets.
  */
  if (state?.game?.name) {
    if (isNil(gameCustomAssets)) {
      gameCustomAssets = {};
    }

    const slotCustomAssets = getCustomAssetsForGame(state.game.name);

    each(slotCustomAssets, (name) => {
      gameCustomAssets[name.split('.')[0]] = {
        path: `${gameCustomAssetsBaseUrl}/${name}`,
      };
    });
  }

  if (gameCustomAssets) {
    assign(gameAssets, gameCustomAssets);
  }

  loader.reset();
  SoundLibrary.unload();

  each(gameAssets, async (value, key) => {
    const data = value;

    if (value.path.endsWith('mp4')) {
      Object.assign(data, {
        resourceOptions: {
          updateFPS: 25,
          autoPlay: true,
          muted: true,
        },
      });
    }

    gameAssetKeys.push(key);

    loader.add({
      alias: key,
      src: value.path,
      data,
    });
  });

  let loadedCount = 0;
  const loadedCountTotal = gameAssetKeys.length;
  const loadedErrorKeys = [];
  const promises = [];

  // eslint-disable-next-line
  for (const gameAssetKey of gameAssetKeys) {
    const promise = new Promise((resolve, reject) => { // eslint-disable-line no-loop-func
      loader.load(gameAssetKey).then((loadedResource) => {
        resources[gameAssetKey] = {
          name: gameAssetKey,
          path: gameAssets[gameAssetKey].path,
          mimeType: gameAssets[gameAssetKey].mimeType,
          resource: loadedResource,
        };

        loadedCount += 1;
        const progressPct = ((loadedCount / loadedCountTotal) * 100) - (manualEnd ? 0.1 : 0);
        window[loaderContext] = Math.ceil(progressPct);
        resolve();
      }, (error) => {
        logger.log(LogType.Warn, 'AssetLoadError', error.message);
        loadedErrorKeys.push(gameAssetKey);
        reject();
      });
    });

    promises.push(promise);
  }

  await Promise.allSettled(promises);

  /*
  Retry failed assets one more time and if it failes
  continue with just logging error.
  */
  if (loadedErrorKeys.length > 0) {
    // eslint-disable-next-line
    for await (const gameAssetKey of loadedErrorKeys) {
      try {
        const loadedResource = await loader.load(gameAssetKey);

        resources[gameAssetKey] = {
          name: gameAssetKey,
          path: gameAssets[gameAssetKey].path,
          mimeType: gameAssets[gameAssetKey].mimeType,
          resource: loadedResource,
        };

        loadedCount += 1;
        const progressPct = ((loadedCount / loadedCountTotal) * 100) - (manualEnd ? 0.1 : 0);
        window[loaderContext] = Math.ceil(progressPct);
      } catch (error) {
        logger.log(LogType.Warn, 'AssetLoadRepeatError', error.message);
      }
    }
  }

  return resources;
}

export async function loadCustomAssets(assets) {
  return loadAssets(undefined, undefined, assets);
}

export function finishLoading() {
  window[loaderContext] = 100;
}

export function registerEventListener(name, event, source, once = false) {
  const sourceName = defaultTo(source, 'Default');
  const uniqueName = `${eventPrefix}${name}`;
  const uniqueHandlerName = `${uniqueName}${sourceName}`;

  if (window[uniqueHandlerName]) {
    document.removeEventListener(uniqueName, window[uniqueHandlerName]);
  }

  window[uniqueHandlerName] = event;
  document.addEventListener(uniqueName, window[uniqueHandlerName], { once });
}

export function triggerEvent(name, detail) {
  const uniqueName = `${eventPrefix}${name}`;

  document.dispatchEvent(new CustomEvent(uniqueName, {
    detail,
  }));
}

export function triggerAnalyticsEvent(detail) {
  const uniqueName = `${eventPrefix}AnalyticsAdd`;

  document.dispatchEvent(new CustomEvent(uniqueName, {
    detail,
  }));
}

export function getAspectRatio() {
  return {
    aspectRatio: window.innerHeight / window.innerWidth,
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function getUuid() {
  return uuid();
}

export function getParticleColorBehavior(colors) {
  let colorBehavior;
  const defaultColors = ['#ebeb17', '#e771f7'];

  if (colors.length === 1 || (colors.length === 2 && colors[0] === colors[1])) {
    colorBehavior = {
      type: 'colorStatic',
      config: {
        color: defaultTo(colors[0], defaultColors[0]),
      },
    };
  } else {
    colorBehavior = {
      type: 'color',
      config: {
        color: {
          list: [
            {
              value: defaultTo(colors[0], defaultColors[0]),
              time: 0,
            },
            {
              value: defaultTo(colors[1], defaultColors[1]),
              time: 1,
            },
          ],
        },
      },
    };
  }

  return colorBehavior;
}

export function fixMultiSprite(allAssets) {
  each(allAssets, (asset) => {
    const { resource } = asset;
    if (resource && resource.data && resource.data.meta) {
      /*
      Normal property in texturer packer is related_multi_pack,
      but we are changing in json files to related since original
      name breaks pixi loader as it does not merge textures as it
      should, but leaves undefined textures from related pack.
      */
      const relatedMultiPacks = resource.data.meta.related;
      if (relatedMultiPacks) {
        let multiPackTextureMap = { ...resource.textures };
        if (relatedMultiPacks.length > 0) {
          each(relatedMultiPacks, (relatedMultiPack) => {
            const relatedAsset = relatedMultiPack.replace('.json', '').replace('-0', '');
            if (allAssets[relatedAsset]) {
              const relatedMultiPackSheet = allAssets[relatedAsset].resource;
              if (relatedMultiPackSheet.textures) {
                multiPackTextureMap = assign(
                  multiPackTextureMap,
                  relatedMultiPackSheet.textures,
                );

                resource.textures = multiPackTextureMap;
              }
            }
          });
        }

        each(resource.data.animations, (animation, animationKey) => {
          const resourceAnimations = resource.animations;
          resourceAnimations[animationKey] = animation.map(
            (frame) => multiPackTextureMap[frame],
          );
        });
      }
    }
  });
}

export const setFullscreen = () => {
  if (document.fullscreenElement === document.body) {
    return;
  }

  const method = document.body.requestFullscreen || document.body.webkitRequestFullScreen;
  const isValidMobile = utils.isMobile.any && !utils.isMobile.other.firefox && !utils.isMobile.apple.phone;
  const isFullscreenModeAvailable = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;

  if (isValidMobile && isFunction(method) && isFullscreenModeAvailable) {
    method.bind(document.body)();
    triggerEvent('FullscreenSet');
  }
};

export function getTextureIndexByChar(textures, char) {
  return findIndex(textures, (texture) => texture.textureCacheIds[0].indexOf(`_${char}.`) > -1);
}

export function isSpriteVideo(sprite) {
  return sprite.texture.baseTexture.resource instanceof VideoResource;
}

export async function callApiWithRetries(method, props = [], retries = 3, wait = 300, error = undefined) {
  if (!retries) {
    return error;
  }

  const result = await method(...props);

  if (result.isError) {
    await sleep(wait);
    return callApiWithRetries(method, props, retries - 1, 300, result);
  }

  return result;
}

export function getPaylineCenter(payline, columnsLength, rowsLength) {
  const paylineWindow = [];

  range(columnsLength).forEach((_, reelIndex) => {
    paylineWindow.push([]);

    range(rowsLength).forEach((symbolIndex) => {
      if (isNil(payline[reelIndex][symbolIndex])) {
        paylineWindow[reelIndex].push(undefined);
      } else {
        paylineWindow[reelIndex].push(payline[reelIndex][symbolIndex]);
      }
    });
  });

  const centerRow = Math.floor((paylineWindow.length - 1) / 2);
  const centerCol = Math.floor((paylineWindow[0].length - 1) / 2);
  let center = null;
  let centerDist = Infinity;

  for (let i = 0; i < paylineWindow.length; i++) {
    for (let j = 0; j < paylineWindow[0].length; j++) {
      if (paylineWindow[i][j]) {
        const dist = Math.abs(i - centerRow) + Math.abs(j - centerCol);
        if (dist < centerDist) {
          center = [i, paylineWindow[i][j]];
          centerDist = dist;
        }
      }
    }
  }

  return center;
}

export function getErrorParams(errorDetails, translations) {
  /* WriteConflict check - used until backend resolve write confilct problem, task: https://app.clickup.com/t/861m867kj */
  const errorMessage = errorDetails?.ui?.label || errorDetails?.message;
  const errorSnackbarType = errorDetails?.ui?.action.toLowerCase() === 'crash' ? 'error' : 'info';
  const errorTranslationKey = includes(errorMessage, 'WriteConflict') ? 'writeConflict' : camelCase(errorMessage);
  const isErrorClosable = errorSnackbarType === 'info';
  const isErrorContinuable = errorDetails?.ui?.action.toLowerCase() === 'continue';
  const isErrorUiDefined = has(errorDetails, 'ui') && !isNil(errorDetails?.ui);
  const isRciError = errorDetails?.ui?.display && errorDetails.message.toLowerCase() === 'rci_error';

  if (isRciError) {
  /* trigger RCI error in client slots */
    triggerEvent('ShowRciError', { ...errorDetails, isRciError });
  }

  const notification = {
    text: defaultTo(translations[errorTranslationKey], errorMessage),
    type: errorSnackbarType,
    isClosable: isErrorClosable,
    isReloadable: !isErrorUiDefined,
    errorMessage,
  };

  const errorParams = {
    isErrorClosable,
    isErrorContinuable,
    isErrorReloadable: !isErrorUiDefined,
    isRciError,
    notification,
  };

  return errorParams;
}

export function getDomain() {
  return process.env.NODE_ENV === 'development' ? '7platform.net' : window.location.origin.split('.').slice(-2).join('.');
}
